import styled, { keyframes } from 'styled-components';

import { colors, font } from '../../constants/theme';

export const StyledLoadingAnimation = styled.div`
  text-align: center;
  color: ${colors.mute};
  font-size: ${font.size.fine.default};
  font-weight: ${font.weight.bold};
  text-transform: uppercase;
`;

const ringSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled.div`
  margin: 20px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.05);
  border-right: 1.1em solid rgba(0, 0, 0, 0.05);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.05);
  border-left: 1.1em solid #ccc;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${ringSpin} 1s infinite linear;
  animation: ${ringSpin} 1s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: ${({size}) => `${size}rem`};
    height: ${({size}) => `${size}rem`};
  }
`;
