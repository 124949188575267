import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Container, Image } from '../../components';
import {
  StyledBackgroundImg,
  StyledHero,
  StyledHeroContent,
} from './styles';
import { AgentIcon } from '../icons';

export const HomeHero = ({
  title,
  mobileSubtitle,
  imagePath,
  ...props
}) => {
  return (
    <StyledHero>
      {imagePath && (
        <StyledBackgroundImg>
          <Image src={imagePath} aria-hidden="true" alt="" />
        </StyledBackgroundImg>
      )}
      <Container {...props}>
        <StyledHeroContent>
          {title && <h1>{title}</h1>}
          {/* {mobileSubtitle && <p>{mobileSubtitle}</p>} */}
        </StyledHeroContent>
      </Container>
    </StyledHero>
  );
};

HomeHero.propTypes = {
  title: PropTypes.string,
  imagePath: PropTypes.string,
};
