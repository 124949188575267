import styled from 'styled-components';

export const StyledSocialShareList = styled.ul`
  display: flex;
  justify-items: flex-end;
  margin: 1rem 0;
  list-style: none;
`;

export const StyledSocialShareListItem = styled.li`
  margin-right: 0.5rem;

  svg {
    width: 2rem;
  }
`;