import React from 'react';
import PropTypes from 'prop-types';

// Components
import { StyledLoadingAnimation, StyledSpinner } from './styles';

export const LoadingAnimation = ({ size = 5, children, ...props }) => {
  return (
    <StyledLoadingAnimation {...props}>
      <StyledSpinner size={size} />
      {children}
    </StyledLoadingAnimation>
  );
};

LoadingAnimation.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number,
};
