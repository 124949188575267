import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// Data
import SEO from '../../utils/seo';
// import { useIntl } from "gatsby-plugin-intl"
// import { useTranslations } from "gatsby-plugin-translate"

// Components
import {
  Header,
  Footer,
  GlobalStyles,
  EndHero,
  Hero,
  Image,
} from '../../components';
import { StyledLayoutWrapper } from './styles';

export const Layout = ({
  pageContext = {},
  heroChildren = {},
  isDarkTheme = false,
  useDarkHeader = true,
  offsetMobileHeader = false,
  noHero = false,
  heroProps,
  children,
}) => {
  const { content, title } = pageContext;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          address
          city
          province
          postalCode
          phone
          email
        }
      }
    }
  `);

  // const t = useTranslations() //useIntl().formatMessage;

  // Converts image src to CSS friendly path
  // const heroImagePath =
  //   content && content.hero.imagePath
  //     ? content.hero.imagePath.indexOf('http') > -1
  //       ? { src: content.hero.imagePath }
  //       : Image({ src: content.hero.imagePath, linkOnly: true })
  //     : '';
  return (
    <>
      <GlobalStyles isDarkTheme={isDarkTheme} />
      <SEO title={pageContext.title} description={pageContext.description} />
      <StyledLayoutWrapper {...{ offsetMobileHeader }}>
        <Header
          darkTheme={useDarkHeader}
          offsetMobileHeader={offsetMobileHeader}
        />
        {!noHero && content && content.hero && (
          <Hero
            title={content.hero.title}
            subtitle={title}
            secondaryTitle={content.hero.secondaryTitle}
            secondaryTitleImgSrc={content.hero.secondaryTitleImage}
            text={content.hero.text}
            imagePath={content.hero.imagePath}
            {...heroProps}>
            {heroChildren}
          </Hero>
        )}
        <main style={{ position: 'relative' }}>
          {children}
          {content && content.endHero && (
            <EndHero
              title={content.endHero.title}
              text={content.endHero.text}
              cta={content.endHero.cta}
            />
          )}
        </main>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </StyledLayoutWrapper>
    </>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.object.isRequired,
  heroChildren: PropTypes.object,
  useDarkHeader: PropTypes.bool,
  isDarkTheme: PropTypes.bool,
  noHero: PropTypes.bool,
  offsetMobileHeader: PropTypes.bool,
};
