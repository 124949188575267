import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils
import { getFilteredArray } from '../../utils/getFilteredArray';

// Components
import { Card, CardGroup, ShoppingIcon } from '../../components';
import { StyledProductPrice } from './styles';

// Data
import products from '../../data/product-list.json';
import { getGoldFeedUrl, getPriceMarkupUrl, fetch } from '../../utils/dao';
import TEST_DATA from '../goldWidget/sample.json';

export const ProductFeed = ({
  columnCount,
  data = products.data,
  exclude,
  feedCount,
  headerProps,
  scrollOnMobile,
  ...props
}) => {
  const baseMarkup = {
    gold: 1.0,
    silver: 1.0,
    platinum: 1.0,
    palladium: 1.0,
  };

  const filteredData = getFilteredArray(data, exclude);
  const [feedData, setFeedData] = useState(null);
  const [markup, setMarkup] = useState(baseMarkup);

  const sanitize = (val) => {
    const num = parseFloat(val).toFixed(2);
    return !isNaN(num) ? num : '0.00';
  };
  
  const getFeed = () => {
    return fetch(getGoldFeedUrl('primary'), null, false)
      .then((resp) => (resp))
      .catch(() => {
        fetch(getGoldFeedUrl('secondary'), null, false)
          .then((resp) => (resp))
          .catch(() => ({ error: 'failed' }))
          });
  };
  
  const getMarkup = () => {
    return fetch(getPriceMarkupUrl(), null, false)
      .then((resp) => (resp))
      .catch(() => (baseMarkup));
  };

  useEffect(() => {
    // Use test data during development
    window.origin.indexOf('localhost') > -1
      ? setTimeout(() => {
          setFeedData(TEST_DATA);
        }, 1000)
      : 
      getFeed().then((resp) => setFeedData(resp));

    getMarkup().then((resp) => setMarkup(resp));
    // Test Error
    // setData({ error: 'failed' });
  }, []);
  return (
    <CardGroup {...{ columnCount, headerProps, scrollOnMobile }} {...props}>
      {filteredData.map((data, i) => {
        const { id, type, name, description, imagePath, weight, cta } = data;
        if (feedCount ? i < feedCount : i < filteredData.length)
          return (
            <Card
              key={id}
              {...{ name, description, imagePath, cta }}
              styleType="card"
              buttonProps={{ icon: <ShoppingIcon />, showArrow: false }}>
              <StyledProductPrice>
                <ul>
                  {feedData ? (<li className="price-value">${parseFloat(sanitize(weight*feedData.bid[type])*markup[type]).toFixed(2)}*</li>) : (<li className="price-value">Call to Ask</li>)}
                </ul>
              </StyledProductPrice>
            </Card>
          );
      })}
    </CardGroup>
  );
};

ProductFeed.propTypes = {
  columnCount: PropTypes.number,
  data: PropTypes.array,
  exclude: PropTypes.array,
  feedCount: PropTypes.number,
  scrollOnMobile: PropTypes.bool,
};
