import styled, { css } from 'styled-components';
import { media, colors, font, header } from '../../constants/theme';

export const StyledHero = styled.header`
  position: relative;

  @media ${media.desktop} {
    margin-bottom: 6rem;
    ${({ extraHeight }) => extraHeight && `padding-bottom: 8rem;`}
  }
  @media ${media.notDesktop} {
    margin-bottom: 4rem;
  }

  @media ${media.fullNav} {
    padding-top: ${header.fullHeight};
  }
  @media ${media.condensedNav} {
    padding-top: ${header.condensedHeight};
  }
`;

export const StyledBackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  > div {
    height: 100%;
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.heroTint};
  }
`;

export const StyleHeaderWrapper = styled.div`
  position: relative;
  color: #fff;
  padding: 5rem 0;
  max-width: 36rem;

  > div {
    z-index: 5;
    @media ${media.notMobile} {
      min-height: 218px;
    }
  }

  span {
    display: block;
    font-weight: ${font.weight.bold};
    text-transform: uppercase;
  }

  @media ${media.notMobile} {
    h1 {
      font-size: ${({ smallerFont }) =>
        smallerFont ? font.size.heading.default : font.size.hero.default};
    }
    p {
      margin-top: 1.5rem;
      font-size: ${font.size.body.default};
    }
  }

  @media ${media.mobile} {
    h1 {
      font-size: ${({ smallerFont }) =>
        smallerFont ? font.size.subheading.mobile : font.size.hero.mobile};
    }
    p {
      margin-top: 1rem;
      font-size: ${font.size.body.mobile};
    }
  }
`;
