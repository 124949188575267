import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

export const Image = ({
  src = '',
  linkOnly,
  alt = 'Westcoast Bullion & Gold Refining',
  ...props
}) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              fluid(quality: 95, maxWidth: 1400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  var images = {};
  data.images.edges.map(
    (img) => (images[img.node.relativePath] = img.node.childImageSharp),
  );
  if (src.indexOf('http') > -1) {
    return <img src={src} alt={alt} />
  }
  if (!images[src]) {
    return <div>Picture not found</div>;
  }
  if (linkOnly) {
    return images[src].fluid;
  }
  return <Img fluid={images[src].fluid} alt={alt} fadeIn={true} backgroundColor={true} {...props} />;
};

Image.propTypes = {
  src: PropTypes.string,
  linkOnly: PropTypes.bool,
};
