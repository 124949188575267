import styled, { css } from 'styled-components';

import { colors, shadow, font, media } from '../../constants/theme';

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    .scale-on-hover {
      transform: scale(1.1);
    }
  }

  /* This div scales on hover */
  .scale-on-hover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: transform 200ms ease-in-out;
  }

  ${({ styleType }) =>
    styleType === 'card' &&
    css`
      box-shadow: ${shadow.card};
      padding: 1.5rem;
      background-color: #fff;
      justify-content: space-between;
      transition: box-shadow 200ms ease-in-out;

      &:hover {
        box-shadow: ${shadow.cardHover};
      }
    `}

  /* Gutter */
  ${({ gutter }) => gutter.top && `margin-top: ${gutter.top};`}
  ${({ gutter }) => gutter.bottom && `margin-bottom: ${gutter.bottom};`}
`;

export const StyledCardContent = styled.div`
  position: relative;
  width: 100%;
  
  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .card-title {
    font-weight: ${font.weight.bold};

    ${({ styleType }) =>
      styleType === 'news'
        ? css`
            font-family: ${font.family.alt};
            font-size: ${font.size.body.default};
            margin: 0.75rem 0;
          `
        : css`
            font-family: ${font.family.default};
            font-size: ${font.size.cardHeading.default};
            margin: 1rem 0;
          `}
  }

  .card-subtitle {
    font-weight: ${font.weight.bold};
    font-size: ${font.size.base.default};
  }

  .card-text {
    font-size: ${font.size.base.default};
  }

  .card-date {
    display: block;
    color: ${colors.mute};
    font-size: ${font.size.info.default};
    font-weight: ${font.weight.bold};
  }

  .card-name {
    font-family: ${font.family.default};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.base.default};
    margin: 0.75rem 0 0.5rem;
  }

  .card-description {
    font-size: ${font.size.fine.default};
    color: ${colors.mute};
    line-height: 1.5;
  }

  ${({ condensed }) => condensed && CondensedLayout}
`;

const CondensedLayout = css`
  display: grid;
  grid-template-columns: 36% 1fr;
  grid-template-rows: auto auto;
  gap: 0.4rem 0.75rem;
  grid-template-areas:
    'image title'
    'image date';

  .card-image-wrapper {
    grid-area: image;
  }

  .card-title {
    grid-area: title;
    font-size: ${font.size.base.default};
    margin: 0;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card-date {
    grid-area: date;
  }
`;

export const StyledImageWrapper = styled.div`
  pointer-events: none;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;

    /* Prevent blurry scaling */
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .gatsby-image-wrapper {
    position: static !important;
  }
`;

export const StyledAspectRatio = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 66.66%;
  overflow: hidden;
  position: relative;
`;
