import styled from 'styled-components';
import { Container } from '../container';
import { media, font } from '../../constants/theme';

export const StyledEndHero = styled.div`
  color: #fff;
  background-color: #2E333B;
  display: flex;
  position: relative;
  overflow: hidden;

  @media ${media.notMobile} {
    padding: 8rem 0;
    margin-top: 8rem;
  }
  @media ${media.mobile} {
    padding: 6rem 0;
    margin-top: 4rem;
  }
`;
