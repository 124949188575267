import styled, { css } from 'styled-components';

import { colors, shadow, font, media } from '../../constants/theme';

export const StyledProductPrice = styled.div`
  display: flex;
  margin-bottom: 0.75rem;

  > ul:first-child {
    margin-right: 1rem;
  }

  .price-label {
    font-size: ${font.size.fine.default};
  }

  .price-value {
    font-weight: ${font.weight.bold};
  }
`;
