import styled, { css } from 'styled-components';
import { colors, media, shadow, font } from '../../constants/theme';

export const StyledContentBlock = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  @media ${media.notMobile} {
    ${({ styleType }) =>
      styleType === 'card' &&
      css`
        box-shadow: ${shadow.card};
        padding: 4rem;
        background-color: #fff;

        @media ${media.mobile} {
          padding: 2rem;
        }
      `}
  }

  @media ${media.desktop} {
    display: flex;

    h2 {
      font-size: ${font.size.heading.default};

      &.title-sm {
        font-size: ${font.size.subheading.default};
        margin-bottom: 1.5rem;
      }
    }

    h3 {
      font-size: ${font.size.subheading.default};
      margin: 2.5rem 0 2.5rem;
    }

    > div:not(:empty) {
      flex: 1;

      &:first-child {
        margin-right: 3rem;
      }
      ${({ reverse }) =>
        reverse &&
        `
          &:first-child {
            order: 2;
            margin-left: 4.5rem;
            margin-right: 0;
          }

          &:last-child {
            order: 1;
          }
        `}
    }
  }

  @media ${media.notDesktop} {
    > div:first-child {
      margin-bottom: 2rem;
    }

    h2 {
      font-size: ${font.size.heading.mobile};
      margin-bottom: 1.5rem;
    }

    h3 {
      font-size: ${font.size.subheading.mobile};
      margin: 1.5rem 0 1.5rem;
    }
  }

  p:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  /* Gutter */
  ${({ gutter }) => gutter.top && `margin-top: ${gutter.top};`}
  ${({ gutter }) => gutter.bottom && `margin-bottom: ${gutter.bottom};`};
`;
