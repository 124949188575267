import React from 'react';
import PropTypes from 'prop-types';

export const DateTime = ({ date = 'test', prefix, suffix, ...props }) => {
  if (!date) return null;
  const [dayOfWeek, month, day, year] = new Date(date).toString().split(' ');
  return (
    <div {...props}>
      {prefix}
      <time dateline={date}>
        {month} {day}, {year}
      </time>
      {suffix}
    </div>
  );
};

DateTime.propTypes = {
  date: PropTypes.string,
};
