import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Container,
  Link,
  LogoStackedLight,
  Button,
  EmailIcon,
  PhoneIcon,
  LocationIcon,
} from '../../components';
import { StyledFooter, StyledFooterGrid } from './styles';

// Data
import data from '../../data/footer.json';

export const Footer = ({ siteTitle = '' }) => {
  const { description, nav_primary, nav_secondary } = data;

  return (
    <StyledFooter>
      <Container>
        <StyledFooterGrid>
          <div className="logo">
            <LogoStackedLight />
          </div>
          <div className="email">
            <h2>
              <EmailIcon />
              Get in touch
            </h2>
            <Button href="mailto:sales@wbce.ca" styleType="link">
              sales@wbce.ca
            </Button>
          </div>
          <div className="phone">
            <h2>
              <PhoneIcon />
              Give us a call
            </h2>
            <Button href="tel:604-616-1010" styleType="link">
              604-616-1010
            </Button>{' '}
            /{' '}
            <Button href="tel:604-616-0500" styleType="link">
              604-616-0500
            </Button>
          </div>
          <div className="address">
            <h2>
              <LocationIcon />
              Visit our office
            </h2>
            <address>Unit 103, 12885 80th Ave, Surrey, BC V3W 0E6</address>
            <p>{description}</p>
          </div>
          <div className="links-1">
            <ul>
              {nav_secondary.map((link, index) => (
                <li key={index}>
                  <Link to={link.src}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="links-2">
            <ul>
              {nav_primary.map((link, index) => (
                <li key={index}>
                  <Link to={link.src}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="copyright">
            <div>
              {siteTitle} © {new Date().getFullYear()}. All Rights Reserved.
            </div>
            <div>
              <a href="/privacy-policy">Privacy Policy</a> | Website by{' '}
              <a
                href="https://www.epikode.com"
                target="_blank"
                rel="nofollow noreferrer noopener">
                Epikode
              </a>
              . V1.0.
            </div>
          </div>
        </StyledFooterGrid>
      </Container>
    </StyledFooter>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};
