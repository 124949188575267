import styled, { css } from 'styled-components';
import { font, media } from '../../constants/theme';

export const StyledCardGroup = styled.div``;
export const StyledGrid = styled.div`
  display: grid;

  @media ${media.desktop} {
    grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);
    gap: 2.5rem;
  }

  @media ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  @media ${media.mobile} {
    grid-template-columns: 1fr;
    gap: 1.5rem 0;
  }

  ${({ scrollOnMobile }) =>
    scrollOnMobile &&
    css`
      @media ${media.mobile} {
        display: block;
        overflow-x: scroll;
        flex-wrap: no-wrap;
        white-space: nowrap;
        margin: -1rem -1.5rem;
        padding: 0 0.5rem;

        > div {
          width: 80%;
          display: inline-block;
          white-space: normal;
          margin: 1rem;
          vertical-align: top;
        }
      }
    `}
`;
