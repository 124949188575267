import React from 'react';
import PropTypes from 'prop-types';

import { StyledCardGroup, StyledGrid } from './styles';
import { Button, HeaderBlock } from '../../components';

export const CardGroup = ({
  children,
  columnCount = 3,
  headerProps,
  scrollOnMobile,
  cta: { src, label } = {},
  ...props
}) => {
  return (
    <StyledCardGroup {...props}>
      {/* Card Header (optional) */}
      {headerProps && <HeaderBlock {...headerProps} />}

      {/* Card Grid */}
      <StyledGrid {...{ columnCount, scrollOnMobile }}>{children}</StyledGrid>
      
      {/* Card Group CTA (optional) */}
      {src && label && (
        <Button to={src} styleType="outline" gutter={{ top: '3rem' }} showArrow>
          {label}
        </Button>
      )}
    </StyledCardGroup>
  );
};

CardGroup.propTypes = {
  children: PropTypes.any,
  columnCount: PropTypes.oneOf([2, 3, 4]),
  headerProps: PropTypes.object,
  scrollOnMobile: PropTypes.bool,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
