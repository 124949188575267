import React from 'react';
import PropTypes from 'prop-types';
import {
  SocialFacebookIcon,
  SocialEnvelopIcon,
  SocialTwitterIcon,
  SocialLinkedinIcon,
} from '../icons';

import { StyledSocialShareList, StyledSocialShareListItem } from './styles';

export const SocialShare = ({ url, text }) => {
  return (
    <StyledSocialShareList>
      <StyledSocialShareListItem>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          target="_blank">
          <SocialFacebookIcon />
        </a>
      </StyledSocialShareListItem>
      <StyledSocialShareListItem>
        <a
          href={`https://twitter.com/intent/tweet?url=${url}&text=${text}`}
          target="_blank">
          <SocialTwitterIcon />
        </a>
      </StyledSocialShareListItem>
      <StyledSocialShareListItem>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
          target="_blank">
          <SocialLinkedinIcon />
        </a>
      </StyledSocialShareListItem>
      <StyledSocialShareListItem>
        <a
          href={`mailto:info@example.com?&subject=&cc=&bcc=&body=${url}`}
          target="_blank">
          <SocialEnvelopIcon />
        </a>
      </StyledSocialShareListItem>
    </StyledSocialShareList>
  );
};

SocialShare.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
};
