import React from 'react';
import PropTypes from 'prop-types';

// Components
import { AgentIcon, ContentBlock } from '../../components';

export const Disclaimer = ({ children, ...props }) => {
  return (
    <ContentBlock
      styleType="card"
      maxWidth="980px"
      cta={{ src: '/contact', label: 'Contact us' }}
      buttonProps={{ styleType: "flat", icon: <AgentIcon />}}
      {...props}>
      <p>
        <strong>
          *As metal prices fluctuates, current posted prices are not indicative of physical trading.
        </strong>{' '}
        Please confirm prices before trading as there are wide spreads between
        bid and ask. Contact us to speak to a custom representative to get more
        information about how to reserve your price to sell or buy your metal.
      </p>
    </ContentBlock>
  );
};

Disclaimer.propTypes = {
  children: PropTypes.any,
};
