import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Image, Link, DateTime } from '../../components';
import {
  StyledCard,
  StyledCardContent,
  StyledImageWrapper,
  StyledAspectRatio,
} from './styles';

export const Card = ({
  buttonProps,
  children,
  condensed,
  cta: { src, label } = {},
  date,
  description,
  gutter = {},
  imagePath,
  name,
  styleType = 'default',
  subtitle,
  text,
  title,
  ...props
}) => {
  return (
    <StyledCard {...{ styleType, gutter }} {...props}>
      <StyledCardContent {...{ styleType, condensed }}>
        {src && <Link to={src} aria-label={title}></Link>}
        {imagePath && (
          <StyledImageWrapper className="card-image-wrapper">
            <StyledAspectRatio>
              <div className="scale-on-hover">
                <Image src={imagePath} alt={title} />
              </div>
            </StyledAspectRatio>
          </StyledImageWrapper>
        )}
        {subtitle && <div className="card-subtitle">{subtitle}</div>}
        {title && <h3 className="card-title">{title}</h3>}
        {name && <h3 className="card-name">{name}</h3>}
        {date && <DateTime date={date} className="card-date" />}
        {text && <p className="card-text">{text}</p>}
        {children}
        {description && <p className="card-description">{description}</p>}
      </StyledCardContent>
      {src && label && (
        <Button
          className="card-cta"
          to={src}
          styleType="link"
          aria-label={`Learn more about ${title}`}
          gutter={{ top: '1rem' }}
          showArrow
          {...buttonProps}>
          {label}
        </Button>
      )}
    </StyledCard>
  );
};

Card.propTypes = {
  buttonProps: PropTypes.object,
  condensed: PropTypes.bool,
  date: PropTypes.string,
  description: PropTypes.string,
  gutter: PropTypes.object,
  imagePath: PropTypes.string,
  name: PropTypes.string,
  styleType: PropTypes.oneOf(['default', 'card', 'news']),
  subtitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
