export const colors = {
  text: '#000000',
  link: '#2E333B',
  mute: '#757575',
  darkMute: '#9e9e9e',

  brand: '#FFBC33',
  brandHover: '#E9A00B',
  brandDark: '#2E333B',
  brandDarkHover: '#424F6A',
  brandLight: '#E6ECED',
  brandLightHover: '#E6ECED',

  brandAlt: '#004F5F', // testimonials
  highlight: '#256a77', // teal

  darkBg: '#2E333B',

  white: '#ffffff',
  whiteHover: '#DCDCDC',
  whiteTint: 'rgba(255, 255, 255, 0.15);',

  black: '#000000',
  blackHover: '#222222',
  blackTint: 'rgba(0, 0, 0, 0.15);',

  heroTint: 'rgba(0, 0, 0, 0.65)',
};

export const font = {
  family: {
    default:
      'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
    alt:
      'Domine, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  },
  weight: {
    normal: 400,
    bold: 600,
    heading: 600,
    button: 600,
    navLinks: 600,
  },
  lineHeight: {
    base: 1.5,
    heading: 1.5,
    paragraphs: 2,
  },
  size: {
    hero: {
      default: '2.75rem',
      mobile: '2.25rem',
    },
    heading: {
      default: '2.25rem',
      mobile: '1.55rem',
    },
    subheading: {
      default: '1.75rem',
      mobile: '1.35rem',
    },
    cardHeading: {
      default: '1.25rem',
      mobile: '1.25rem',
    }, 
    body: {
      default: '1.125rem',
      mobile: '1rem',
    },    
    base: {
      default: '1rem',
      mobile: '1rem',
    },    
    info: {
      default: '0.875rem',
      mobile: '0.875rem',
    },    
    fine: {
      default: '0.75rem',
      mobile: '0.75rem',
    },
  },
};

export const shadow = {
  card: '2px 2px 16px rgba(0, 0, 0, 0.16)',
  cardHover: '4px 4px 16px rgba(0, 0, 0, 0.26)',
  text: '0 0 6px rgba(0, 0, 0, 0.5)',
}

export const header = {
  fullHeight: '120px',
  negativeFullHeight: '-120px',
  condensedHeight: '80px',
  negativeCondensedHeight: '-80px',

  stickyBgColor: 'rgba(0,0,0,0.85)',
  logoHeight: '60px',

  mobile: {
    bgColor: 'rgba(0,0,0,0.97)',
    logoHeight: '45px',
  }
};

export const screenMin = {
  xxs: 375,
  xs: 480,
  sm: 768,
  md: 996,
  lg: 1280,
  xlg: 1440,
  xxlg: 1580,
};

export const screenMax = {
  xxs: screenMin.xs - 1,
  xs: screenMin.sm - 1,
  sm: screenMin.md - 1,
  md: screenMin.lg - 1,
  lg: screenMin.xlg - 1,
  xlg: screenMin.xxlg - 1,
};

export const minWidth = {
  xs: `(min-width: ${screenMin.xs}px)`,
  sm: `(min-width: ${screenMin.sm}px)`,
  md: `(min-width: ${screenMin.md}px)`,
  lg: `(min-width: ${screenMin.lg}px)`,
  xlg: `(min-width: ${screenMin.xlg}px)`,
  xxlg: `(min-width: ${screenMin.xxlg}px)`,
};

export const maxWidth = {
  xxs: `(max-width: ${screenMax.xxs}px)`,
  xs: `(max-width: ${screenMax.xs}px)`,
  sm: `(max-width: ${screenMax.sm}px)`,
  md: `(max-width: ${screenMax.md}px)`,
  lg: `(max-width: ${screenMax.lg}px)`,
  xlg: `(max-width: ${screenMax.xlg}px)`,
};

export const breakPoint = {
  xxs: maxWidth.xxs,
  xs: `${minWidth.xs} and ${maxWidth.xs}`,
  sm: `${minWidth.sm} and ${maxWidth.sm}`,
  md: `${minWidth.md} and ${maxWidth.md}`,
  lg: `${minWidth.lg} and ${maxWidth.lg}`,
  xlg: `${minWidth.xlg} and ${maxWidth.xlg}`,
  xxlg: minWidth.xxlg,
};

// Specifically for useWindowSize
export const mediaSize = {
  mobile: screenMax.xs,
  notMobile: screenMin.sm,
  desktop: screenMin.md,
  notDesktop: screenMax.sm,
  condensedNav: 1175,
  fullNav: 1176,
};

export const media = {
  mobile: `(max-width: ${mediaSize.mobile}px)`,
  notMobile: `(min-width: ${mediaSize.notMobile}px)`,
  tablet: breakPoint.sm,
  desktop: `(min-width: ${mediaSize.desktop}px)`,
  notDesktop: `(max-width: ${mediaSize.notDesktop}px)`,
  condensedNav: `(max-width: ${mediaSize.condensedNav}px)`,
  fullNav: `(min-width: ${mediaSize.fullNav}px)`,
};

export const button = {
  base: {
    borderRadius: '0px',
    borderWidth: '2px',
    height: '46px',
    fontSize: '16px',
    transition: 'all 200ms ease-in',
    weight: font.weight.button,
    padding: '0 1.25rem',
  },
  colors: {
    brand: {
      default: {
        initial: {
          color: colors.black,
          bgColor: colors.brand,
          borderColor: colors.brand,
        },
        hover: {
          color: colors.black,
          bgColor: colors.brandHover,
          borderColor: colors.brandHover,
        },
      },
      reversed: {
        initial: {
          color: colors.brandDark,
          bgColor: colors.white,
          borderColor: colors.white,
        },
        hover: {
          color: colors.brandDark,
          bgColor: colors.whiteHover,
          borderColor: colors.whiteHover,
        },
      },
    },
    flat: {
      default: {
        initial: {
          color: colors.white,
          bgColor: colors.black,
          borderColor: colors.black,
        },
        hover: {
          color: colors.white,
          bgColor: colors.blackHover,
          borderColor: colors.blackHover,
        },
      },
      reversed: {
        initial: {
          color: colors.black,
          bgColor: colors.white,
          borderColor: colors.white,
        },
        hover: {
          color: colors.black,
          bgColor: colors.whiteHover,
          borderColor: colors.whiteHover,
        },
      },
    },
    outline: {
      default: {
        initial: {
          color: colors.brandDark,
          bgColor: 'transparent',
          borderColor: colors.brandDark,
        },
        hover: {
          color: colors.brandDark,
          bgColor: colors.blackTint,
          borderColor: colors.brandDark,
        },
      },
      reversed: {
        initial: {
          color: colors.white,
          bgColor: 'transparent',
          borderColor: colors.white,
        },
        hover: {
          color: colors.white,
          bgColor: colors.whiteTint,
          borderColor: colors.white,
        },
      },
    },
    link: {
      default: {
        initial: {
          color: colors.text,
          bgColor: 'transparent',
          borderColor: 'transparent',
        },
        hover: {
          color: colors.brandDark,
          bgColor: 'transparent',
          borderColor: 'transparent',
        },
      },
      reversed: {
        initial: {
          color: colors.white,
          bgColor: 'transparent',
          borderColor: 'transparent',
        },
        hover: {
          color: colors.whiteHover,
          bgColor: 'transparent',
          borderColor: 'transparent',
        },
      },
    },
  },
};
