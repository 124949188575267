import React from 'react';
import PropTypes from 'prop-types';

// Components
import { StyledHeaderBlock } from './styles';

export const HeaderBlock = ({ title, text, ...props }) => {
  return (
    <StyledHeaderBlock {...props}>
      {title && <h2>{title}</h2>}
      {text && <p>{text}</p>}
    </StyledHeaderBlock>
  );
};

HeaderBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};
