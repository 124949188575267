import styled, { css } from 'styled-components';
import { media } from '../../constants/theme';

export const StyledContainer = styled.section`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  ${({ fluid, maxWidth }) =>
    !fluid ? `max-width: ${maxWidth || `1280px`};` : undefined}

  /* Gutter */
  ${({ gutter }) => gutter.top && `margin-top: ${gutter.top};`}
  ${({ gutter }) => gutter.bottom && `margin-bottom: ${gutter.bottom};`}

  /* Offset Hero */
  ${({ offsetHero }) =>
    offsetHero &&
    `
    @media ${media.notMobile} {
      padding-top: 10rem;
    }
  `}

  ${({ addSpacers }) =>
    addSpacers &&
    css`
      > * {
        @media ${media.desktop} {
          margin-bottom: 8rem;
        }
        @media ${media.notDesktop} {
          margin-bottom: 4rem;
        }
      }
    `}
`;
