import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, GoldWidget } from '../../components';
import {
  StyledHomeContent,
  StyledHeroBoxes,
  StyledHeroTagline,
} from './styles';

export const HomeContent = ({
  subtitle,
  text,
  cta: { src, label } = {},
  ...props
}) => {
  return (
    <StyledHomeContent {...props}>
      <StyledHeroBoxes>
        <StyledHeroTagline className="hero-tagline">
          {subtitle && <h2>{subtitle}</h2>}
          {text && <p>{text}</p>}
          {src && label && (
            <Button to={src} styleType="outline" reversed showArrow>
              {label}
            </Button>
          )}
        </StyledHeroTagline>
        <GoldWidget className="gold-widget" />
      </StyledHeroBoxes>
    </StyledHomeContent>
  );
};

HomeContent.propTypes = {
  subtitle: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
