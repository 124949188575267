import React from 'react';

import { convertStringToKey } from '../../utils/convertStringToKey';

import {
  ContentBlock,
  Container,
  SecureIcon,
  CheckmarkOutlineIcon,
} from '../../components';

import { StyledOrderProcess } from './styles';

const data = {
  payment: [
    'Online Bill Payment',
    'Bank Draft',
    'Paypal',
    'Interac Email Transfer',
    'Money Order',
    'Credit Card',
    'Wire Transfer',
    'Cheque',
  ],
  shipping: [
    'FedEx',
    'Canada Post',
    'In Store Pickup',
    'Store in our vault',
  ],
};

export const OrderProcess = ({ ...props }) => {
  return (
    <StyledOrderProcess {...props}>
      <ContentBlock
        title="Our order process is fast, convenient and secure."
        titleChildren={<SecureIcon />}
        styleType="card">
        <div>
          <h3 className="content-block-heading">Payment Options</h3>
          <ul>
            {data.payment.map((item, i) => {
              return (
                <li key={convertStringToKey(item)}>
                  <CheckmarkOutlineIcon />
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <h3 className="content-block-heading">Shipping Options</h3>
          <ul>
            {data.shipping.map((item, i) => {
              return (
                <li key={convertStringToKey(item)}>
                  <CheckmarkOutlineIcon />
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </ContentBlock>
    </StyledOrderProcess>
  );
};
