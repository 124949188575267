import styled, { css } from 'styled-components';

import { Link } from '../link';
import { button } from '../../constants/theme';

const colorType = (reversed) => (reversed ? 'reversed' : 'default');

const BaseStyles = css`
  /* Base styles */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${button.base.borderRadius};
  font-size: ${button.base.fontSize};
  height: ${button.base.height};
  transition: ${button.base.transition};
  font-weight: ${button.base.weight};
  text-transform: uppercase;
  line-height: 1;
  text-decoration: none;

  /* Border widths and padding */
  ${({ $styleType }) =>
    $styleType !== 'link'
      ? `
        padding: ${button.base.padding};
        border-width: ${button.base.borderWidth};
        border-style: solid;
        `
      : `padding: 0;`}

  /* Colors */
  ${({ $reversed, $styleType }) =>
    `
      color: ${button.colors[$styleType][colorType($reversed)].initial.color};
      border-color: ${
        button.colors[$styleType][colorType($reversed)].initial.borderColor
      };
      background-color: ${
        button.colors[$styleType][colorType($reversed)].initial.bgColor
      };

      &:hover {
        color: ${button.colors[$styleType][colorType($reversed)].hover.color};
        border-color: ${
          button.colors[$styleType][colorType($reversed)].hover.borderColor
        };
        background-color: ${
          button.colors[$styleType][colorType($reversed)].hover.bgColor
        };
      }
    `}

  &:active {
    transform: scale(0.95);
  }

  ${({ disabled }) =>
    disabled
      ? `
      &, &:disabled {
        background-color: #909090;
        border-color: #909090;
        pointer-events: none;
      `
      : undefined}

  /* Gutter */
  ${({ $gutter }) => $gutter.top && `margin-top: ${$gutter.top};`}
  ${({ $gutter }) => $gutter.bottom && `margin-bottom: ${$gutter.bottom};`}
  ${({ $gutter }) => $gutter.left && `margin-left: ${$gutter.left};`}
  ${({ $gutter }) => $gutter.right && `margin-right: ${$gutter.right};`}

  /* Icons */
  svg {
    width: 24px;
    height: 24px;
    display: block;
    transition: ${button.base.transition};
  }
  ${({ $hasIcon }) =>
    $hasIcon &&
    `
    span {
      padding-left: 8px;
    }
  `}
  ${({ $hasArrow }) =>
    $hasArrow &&
    `
    span {
      padding-right: 4px;
    }

    &:hover {
      svg {
        transform: translateX(6px);
      }
    }
  `}
`;

export const StyledButton = styled.button`
  /* Resets */
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  user-select: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  /* Styles */
  ${BaseStyles}
`;

export const StyledLink = styled(Link)`
  ${BaseStyles}
`;

export const StyledAnchor = styled.a`
  ${BaseStyles}
`;
