import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledButton,
  StyledLink,
  StyledAnchor,
} from './styles';

import { ChevronRightIcon } from '../icons';

const styleTypes = ['brand', 'flat', 'outline', 'link'];

export const Button = ({
  styleType = 'flat',
  reversed = false,
  showArrow = false,
  gutter = {},
  children,
  type,
  href,
  disabled,
  icon,
  ...props
}) => {
  const ButtonComponent = type
    ? StyledButton
    : href
    ? StyledAnchor
    : StyledLink;
  return (
    <ButtonComponent
      type={type}
      href={href}
      $styleType={styleTypes.includes(styleType) ? styleType : 'flat'}
      $reversed={reversed}
      $gutter={gutter}
      disabled={disabled}
      $hasIcon={icon}
      $hasArrow={showArrow}
      {...props}>
      {icon}
      <span>{children}</span>
      {showArrow && <ChevronRightIcon />}
    </ButtonComponent>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  styleType: PropTypes.oneOf(styleTypes),
  reversed: PropTypes.bool,
  showArrow: PropTypes.bool,
  gutter: PropTypes.object,
  icon: PropTypes.node,
};
