import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { createGlobalStyle } from 'styled-components';

// Const & Utils
import { useWindowSize } from '../../utils/useWindowSize';
import { convertStringToKey } from '../../utils/convertStringToKey';
import { mediaSize } from '../../constants/theme';

// Components
import {
  LogoLight,
  LogoDark,
  LogoStackedLight,
  ChartIcon,
} from '../../components';
import {
  StyledHeader,
  StyledContainer,
  StyledNav,
  StyledMobileToggle,
  StyledMobileNav,
} from './styles';
import { Link, Button } from '../../components';

// Data
import data from '../../data/header.json';

const createNavSection = (data, isMobile) => {
  return (
    <ul className="header-main-nav">
      {data.map(({ label, labelMobile, src, children, highlight }, i) => (
        <li
          key={convertStringToKey(label)}
          className={children && children.length > 0 ? 'has-sub-nav' : ''}>
          {highlight ? (
            <Button
              href={src}
              className="main-nav-button"
              styleType="brand"
              icon={<ChartIcon />}>
              {isMobile ? labelMobile : label}
            </Button>
          ) : (
            <Link to={src} className="main-nav-links">
              {isMobile ? labelMobile : label}
            </Link>
          )}

          {children && children.length > 0 && (
            <ul className="header-sub-nav">
              {children.map(({ label, labelMobile, src }, i) => {
                return (
                  <li
                    key={convertStringToKey(label)}
                    className={!labelMobile ? 'hide-in-mobile' : ''}>
                    <Link to={src} className="sub-nav-links">
                      {isMobile ? `⇀ ${labelMobile || label}` : label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

const PreventBodyOverflowStyles = createGlobalStyle`
    body {
      overflow: hidden;
    }
    `;

export const Header = ({ darkTheme, offsetMobileHeader }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [allowSticky, setAllowSticky] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < 0) {
        setAllowSticky(true);
      } else {
        setAllowSticky(false);
      }
      // Set sticky if user scrolls up, unset if scroll down
      if (currPos.y > prevPos.y) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    },
    [sticky, allowSticky],
    null,
    null,
    300,
  );

  const { nav } = data;
  const handleMenuOpen = () => setMenuOpen(!menuOpen);
  const condensedNav = useWindowSize().width <= mediaSize.condensedNav;
  const isDarkTheme = darkTheme || (sticky && allowSticky);

  return (
    <StyledHeader
      darkTheme={isDarkTheme}
      sticky={sticky}
      allowSticky={allowSticky}
      offsetMobileHeader={offsetMobileHeader}>
      <StyledContainer>
        {condensedNav ? (
          <>
            {menuOpen && <PreventBodyOverflowStyles />}
            <Link to="/" className="header-logo">
              {isDarkTheme ? <LogoDark /> : <LogoLight />}
            </Link>
            <StyledMobileToggle
              aria-label={`${menuOpen ? 'close' : 'open'} website menu`}
              menuOpen={menuOpen}
              darkTheme={isDarkTheme}
              onClick={handleMenuOpen}>
              <span></span>
              <span></span>
            </StyledMobileToggle>

            {/* Mobile navigation drawer */}
            <StyledMobileNav
              menuOpen={menuOpen}
              aria-label="site main navigation">
              <div className="logo-wrapper">
                <LogoStackedLight />
              </div>
              {createNavSection(Object.values(nav), true)}
            </StyledMobileNav>
          </>
        ) : (
          <>
            <Link to="/" className="header-logo">
              {isDarkTheme ? <LogoDark /> : <LogoLight />}
            </Link>
            <StyledNav
              menuOpen={menuOpen}
              darkTheme={isDarkTheme}
              aria-label="site main navigation">
              {createNavSection(Object.values(nav))}
            </StyledNav>
          </>
        )}
      </StyledContainer>
    </StyledHeader>
  );
};

Header.propTypes = {
  darkTheme: PropTypes.bool,
};
