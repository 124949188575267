import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Link, Image, Button, DateTime } from '../../components';
import { StyledNewsCard, StyledNewsContent } from './styles';

export const NewsCard = ({
  cta: { src, label } = {},
  date,
  gutter = {},
  imagePath,
  text,
  title,
  buttonProps,
  ...props
}) => {
  return (
    <StyledNewsCard {...{ gutter }} {...props}>
      <div>
        <Image src={imagePath} alt={title} />
      </div>
      <div>
        <StyledNewsContent>
          {src && <Link to={src} aria-label={title}></Link>}
          {title && <h2>{title}</h2>}
          {date && <DateTime date={date} />}
          {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
        </StyledNewsContent>
        {src && label && (
          <Button
            to={src}
            styleType="link"
            aria-label={`Read article about ${title}`}
            gutter={{ top: '1rem' }}
            showArrow
            {...buttonProps}>
            {label}
          </Button>
        )}
      </div>
    </StyledNewsCard>
  );
};

NewsCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  date: PropTypes.string,
  imagePath: PropTypes.string,
  gutter: PropTypes.object,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
