import styled from 'styled-components';
import { Container } from '../container';
import { colors, media, font, header, button } from '../../constants/theme';

export const StyledHeader = styled.div`
  transition: all 450ms ease-in;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;

  @media ${media.condensedNav} {
    top: ${({ allowSticky, sticky }) => {
      if (allowSticky && sticky) return '0';
      if (allowSticky && !sticky) return header.negativeCondensedHeight;
    }};
  }
  @media ${media.fullNav} {
    top: ${({ allowSticky, sticky }) => {
      if (allowSticky && sticky) return '0';
      if (allowSticky && !sticky) return header.negativeFullHeight;
    }};
  }

  ${({ darkTheme, offsetMobileHeader }) =>
    darkTheme
      ? `
      color: #fff;
      background-color: ${offsetMobileHeader ? colors.darkBg : 'transparent'};
    `
      : `
      color: ${colors.brand};
      background-color: ${offsetMobileHeader ? '#fff' : 'transparent'};

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-color: #fff;
      }
  `}

  ${({ sticky, allowSticky }) =>
    sticky && allowSticky
      ? `
    position: fixed;
    background-color: ${header.stickyBgColor} !important;
  `
      : undefined}

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .header-logo {
    display: block;

    @media ${media.fullNav} {
      height: ${header.logoHeight};
    }
    @media (max-width: 1100px) {
      height: 35px;
    }
    @media ${media.condensedNav} {
      height: ${header.mobile.logoHeight};
    }

    svg {
      height: 100%;
    }
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  align-content: center;
  align-items: center;

  @media ${media.condensedNav} {
    height: ${header.condensedHeight};
  }
  @media ${media.fullNav} {
    height: ${header.fullHeight};
  }
`;

// Desktop navigation
export const StyledNav = styled.nav`
  margin-left: auto;

  @media ${media.notDesktop} {
    display: none;
  }

  ul {
    display: flex;
  }

  li {
    padding: 0 1.2rem;
    position: relative;

    /* Opens desktop sub navigation */
    &:hover .header-sub-nav {
      max-height: 350px;
    }
  }

  /* Desktop links */
  a:not(.main-nav-button) {
    display: block;
    font-weight: ${font.weight.navLinks};
    padding: 0.8rem 0;
    position: relative;
    text-decoration: none;

    &,
    &:hover {
      color: ${({ darkTheme }) => (darkTheme ? '#fff' : colors.link)};
    }

    &.main-nav-links {
      :after,
      :before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        height: 1px;
        width: 0;
        background: ${({ darkTheme }) => (darkTheme ? '#fff' : colors.link)};
        transition: width 200ms ease-in-out 0s, left 200ms ease-in-out 0s;
      }
      :after {
        bottom: 0;
      }
      :before {
        top: 0;
      }
      &:hover:after,
      &:hover:before {
        width: 100%;
        left: 0;
      }
    }
  }

  /* Desktop: Sub navigation */
  ul.header-sub-nav {
    display: block;
    position: absolute;
    top: 100%;
    left: -67px;
    background-color: #000;
    min-width: 290px;
    z-index: 1;
    max-height: 0;
    transition: max-height 450ms ease-in-out;
    overflow: hidden;
    padding: 0 1rem;

    li:first-child {
      padding-top: 0.5rem;
    }

    li:last-child {
      padding-bottom: 0.5rem;
    }

    a {
      transition: ${button.base.transition};

      &:hover {
        color: ${colors.brand};
      }
    }
  }
`;

export const StyledMobileToggle = styled.button`
  /* Resets */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  user-select: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  /* Styles */
  margin-left: auto;
  z-index: 1100;
  width: 2rem;
  height: 2rem;
  ${({ menuOpen }) =>
    menuOpen
      ? `
      position: fixed;
      right : 24px;
      `
      : `
      position: relative;
  `};

  &:focus {
    outline: none;
  }

  span {
    position: absolute;
    width: 2rem;
    height: 2px;
    border-radius: 2px;
    background-color: ${({ darkTheme }) => (darkTheme ? '#fff' : colors.brand)};
    transition: 250ms ease-in;
    opacity: 1;
    left: 0;

    &:nth-child(1) {
      top: calc(1rem - 0.3rem);
    }
    &:nth-child(2) {
      top: calc(1rem + 0.3rem);
    }

    ${({ menuOpen }) =>
      menuOpen &&
      `
      background-color: #fff;

			&:nth-child(1) {
        transform: rotate(-45deg);
        top: 1rem;
			}
			&:nth-child(2) {
        transform: rotate(45deg);
        top: 1rem;
			}
    `};
  }
`;

export const StyledMobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${header.mobile.bgColor};
  color: #fff;
  position: fixed;
  transition: left 350ms ease-in-out;
  transition-delay: 200ms;
  top: 0;
  left: ${({ menuOpen }) => (menuOpen ? '0' : '-100%')};
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 0 1.5rem;

  .logo-wrapper {
    height: ${header.condensedHeight};
    display: flex;
    align-items: center;

    svg {
      height: ${header.mobile.logoHeight};
    }
  }

  ul.header-main-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 2rem;

    li {
      position: relative;
    }
  }

  a.main-nav-links,
  a.sub-nav-links {
    font-size: ${font.size.body.default};
    display: block;
    color: #fff;
    padding: 0.75rem 0;
    text-decoration: none;
  }

  a.main-nav-button {
    margin-top: 1rem;
  }

  ul.header-sub-nav {
    a.sub-nav-links {
      font-size: ${font.size.info.default};
      padding: 0.60rem 0;
    }
    li.hide-in-mobile {
      display: none;
    }
  }
`;

export const StyledBgWrapper = styled.div`
  z-index: -1;
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  > div {
    width: 130%;
    top: -7rem;
    left: -15%;
  }
`;
