import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Container, Image } from '../../components';
import { StyledHero, StyleHeaderWrapper, StyledBackgroundImg } from './styles';

export const Hero = ({
  imagePath,
  title,
  text,
  extraHeight = false,
  smallerFont = false,
  ...props
}) => {
  return (
    <StyledHero {...{ imagePath, extraHeight }} {...props}>
      {imagePath && (
        <StyledBackgroundImg>
          <Image src={imagePath} aria-hidden="true" alt="" />
        </StyledBackgroundImg>
      )}
      <Container as="div">
        <StyleHeaderWrapper smallerFont={smallerFont}>
          <div>
            {title && <h1>{title}</h1>}
            {text && <p>{text}</p>}
          </div>
        </StyleHeaderWrapper>
      </Container>
    </StyledHero>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  imagePath: PropTypes.string,
  extraHeight: PropTypes.bool,
  smallerFont: PropTypes.bool,
};
