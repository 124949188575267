export const API_PATH = 'https://wcb-cms.epikode.com/';
const POST_ENDPOINT = 'blogs';
const PRICE_MARKUP_ENDPOINT = 'price-markup';
const GOLD_FEED_URL = 'https://livegoldfeed.com/goldfeed/profile/frame/9e7cdcf2b125351fa06f573c14876933/price?currency=cad&units=toz';
const GOLD_FEED_URL_BACKUP = 'https://api.epikode.com/cors?url=https%3A%2F%2Flivegoldfeed.com%2Fgoldfeed%2Fprofile%2Fframe%2F9e7cdcf2b125351fa06f573c14876933%2Fprice%3Fcurrency%3Dcad%26units%3Dtoz';

let cache = {};


export const createSlug = (Text) =>
Text.toLowerCase()
  .replace(/ /g, '-')
  .replace(/[^\w-]+/g, '');

export const encodeParams = (params = {}) => {
  return Object.keys(params)
  .map(key => `${key}=${params[key]}`)
  .join('&');
};

export const getPostsUrl = (params = {}) => {
  return Object.keys(params).length > 0 ? `${API_PATH}${POST_ENDPOINT}?${encodeParams(params)}` : `${API_PATH}${POST_ENDPOINT}`;
};

export const getPriceMarkupUrl = () => {
  return `${API_PATH}${PRICE_MARKUP_ENDPOINT}`
}

export const getGoldFeedUrl = (type) => {
  return type === 'primary' ? GOLD_FEED_URL : GOLD_FEED_URL_BACKUP; 
};

export const fetch = (endpoint = '', options = {
  method: 'GET',
  headers: {
      'Content-Type': 'application/json; charset=utf-8',
  },
}, nocache = false) => {
  // Caching mechanism for repeated calls
  if (cache[endpoint] && !nocache) return Promise.resolve(cache[endpoint]);
  else 
  return window.fetch(endpoint, options)
    .then((response) => {
      const {
        ok,
        status,
        statusText,
        url,
      } = response;

      if (ok) {
        const json = response.json();
        cache[endpoint] = json;
        return json;
      }

      const error = {
        message: `${status}: ${statusText}, failure for call to ${url}`,
        response,
        status,
        statusText,
      };

      return Promise.reject(error);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}