import styled, { css } from 'styled-components';
import { colors, media, font } from '../../constants/theme';

export const StyledGoldWidget = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${media.desktop} {
    margin-left: auto;
    margin-right: auto;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  }

  @media ${media.mobile} {
    min-height: 614px;
  }

  h2 {
    font-size: ${font.size.subheading.default};
    font-family: ${font.family.default};
    margin-bottom: 24px;

    @media ${media.mobile} {
      font-size: ${font.size.subheading.mobile};
    }
  }
`;

export const StyledTable = styled.div`
  display: grid;
  row-gap: 24px;
`;

export const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'metal change bid-price ask-price';

  @media ${media.mobile} {
    margin-bottom: 16px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      'metal change'
      'bid-price bid-price'
      'ask-price ask-price';
  }

  .metal {
    grid-area: metal;
    text-transform: capitalize;
    font-family: ${font.family.default};

    @media ${media.notMobile} {
      font-size: ${font.size.body.default};
    }
  }

  .change {
    grid-area: change;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4px;
  }

  .bid-price {
    grid-area: bid-price;
  }

  .ask-price {
    grid-area: ask-price;
  }

  .change-badge {
    font-size: ${font.size.fine.default};
    font-weight: ${font.weight.bold};
    padding: 4px 8px;
    border-radius: 6px;
    white-space: nowrap;
    align-self: start;
    text-align: center;

    &.positive {
      color: #008d3c;
      background-color: #deffec;
    }

    &.negative {
      color: #b10000;
      background-color: #ffeaea;
    }
  }

  .bid-price,
  .ask-price {
    text-align: right;
    font-weight: ${font.weight.bold};

    @media ${media.mobile} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;

      .price-value {
        order: 2;
      }
      .price-label {
        order: 1;
      }
    }

    .price-label {
      display: block;
      color: ${colors.mute};
      font-size: ${font.size.fine.default};
      font-weight: ${font.weight.bold};
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
`;

export const StyledLastRow = styled.div`
  margin-top: 24px;
  color: ${colors.mute};
  font-size: ${font.size.fine.default};
  font-weight: ${font.weight.bold};
  text-transform: uppercase;
`;
