import styled from 'styled-components';

import { media, font, colors } from '../../constants/theme';

export const StyledNewsCard = styled.div`
  /* Gutter */
  margin-top: ${({ gutter }) => gutter.top || '2rem'};
  margin-bottom: ${({ gutter }) => gutter.bottom || '4rem'};

  @media ${media.desktop} {
    display: flex;
    align-items: flex-start;
  }

  > div:first-child {
    @media ${media.desktop} {
      width: 45%;
      flex-shrink: 0;
      height: 350px;
    }

    @media ${media.notDesktop} {
      height: 280px;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    @media ${media.tablet} {
      height: 400px;
    }

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  > div:last-child {
    @media ${media.desktop} {
      width: 50%;
      flex-shrink: 0;
      margin-left: auto;
    }
  }
`;

export const StyledNewsContent = styled.div`
  position: relative;

  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  h2 {
    font-size: ${font.size.subheading.default};
    margin-bottom: 1rem;

    @media ${media.notDesktop} {
      font-size: ${font.size.subheading.mobile};
    }
  }

  time {
    display: block;
    color: ${colors.mute};
    font-size: ${font.size.info.default};
    font-weight: ${font.weight.bold};
    margin-bottom: 1rem;
  }
`;
