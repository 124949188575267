import styled from 'styled-components';

export const StyledContactCard = styled.div`
  h2 { 
    font-size: 24px;
    margin-bottom: 1rem;
  }
  address {
    line-height: 1.5;
  }
`;
