import React, { useEffect, useState } from 'react';
import { getGoldFeedUrl, getPriceMarkupUrl, fetch } from '../../utils/dao';
import TEST_DATA from './sample.json';

// Components
import { LoadingAnimation } from '../../components';
import {
  StyledGoldWidget,
  StyledTable,
  StyledRow,
  StyledLastRow,
} from './styles';

const sanitize = (val) => {
  const num = parseFloat(val).toFixed(2);
  return !isNaN(num) ? num : '0.00';
};

const getFeed = () => {
  return fetch(getGoldFeedUrl('primary'), null, false)
    .then((resp) => (resp))
    .catch(() => {
      fetch(getGoldFeedUrl('secondary'), null, false)
        .then((resp) => (resp))
        .catch(() => ({ error: 'failed' }))
        });
};

const baseMarkup = {
  gold: 1.0,
  silver: 1.0,
  platinum: 1.0,
  palladium: 1.0,
};

const getMarkup = () => {
  return fetch(getPriceMarkupUrl(), null, false)
    .then((resp) => (resp))
    .catch(() => (baseMarkup));
};

export const GoldWidget = ({ maxWidth, ...props }) => {
  const [data, setData] = useState(null);
  const [markup, setMarkup] = useState(baseMarkup);
  const date = new Date().toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  useEffect(() => {
    // Use test data during development
    window.origin.indexOf('localhost') > -1
      ? setTimeout(() => {
          setData(TEST_DATA);
        }, 1000)
      : 
      getFeed().then((resp) => setData(resp));

    getMarkup().then((resp) => setMarkup(resp))
    // Test Error
    // setData({ error: 'failed' });
  }, []);

  // Don't render component if no data
  if (data && data.error) return null;

  return (
    <StyledGoldWidget maxWidth={maxWidth} {...props}>
      <h2>Live Metal Prices</h2>

      {/* Data table */}
      {data && data.bid && (
        <StyledTable>
          {Object.keys(data.bid).map((key) => {
            const type = data.change_percent[key] < 0 ? 'negative' : 'positive';
            return (
              <StyledRow key={key}>
                <h3 className="metal">{key}</h3>
                <div className="change">
                  <span className={`change-badge change-value ${type}`}>
                    {data.change_percent[key] > 0 ? '+' : ''}
                    {sanitize(data.change_dollar[key])}
                  </span>
                  <span className={`change-badge change-percent ${type}`}>
                    {data.change_percent[key] > 0 ? '+' : ''}
                    {sanitize(data.change_percent[key])}%
                  </span>
                </div>
                <div className="bid-price">
                  <span className="price-value">
                    ${parseFloat(sanitize(data.bid[key])*markup[key]).toFixed(2)}
                  </span>
                  <span className="price-label">cad bid</span>
                </div>
                <div className="ask-price">
                  <span className="price-value">
                    ${parseFloat(sanitize(data.ask[key])*markup[key]).toFixed(2)}
                  </span>
                  <span className="price-label">cad ask</span>
                </div>
              </StyledRow>
            );
          })}
        </StyledTable>
      )}

      {/* Loading */}
      {!data && <LoadingAnimation>Loading data</LoadingAnimation>}

      {/* Last updated information */}
      <StyledLastRow>
        {data && !data.error && <>Last Updated {date}</>}
      </StyledLastRow>
    </StyledGoldWidget>
  );
};
