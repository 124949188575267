import React from 'react';
import PropTypes from 'prop-types';

import { Button, Image } from '../../components';
import { StyledContentBlock } from './styles';

export const ContentBlock = ({
  buttonProps = { styleType: 'outline', showArrow: true },
  children,
  content,
  cta: { src, label } = {},
  gutter = {},
  imagePath,
  maxWidth = 'none',
  reverse = false,
  styleType = 'default',
  title,
  titleChildren,
  ...props
}) => {
  return (
    <StyledContentBlock
      {...{
        maxWidth,
        gutter,
        styleType,
        reverse,
      }}
      {...props}>
      <div>
        {imagePath ? (
          <Image src={imagePath} alt={title} />
        ) : (
          <>
            {title && <h2>{title}</h2>}
            {titleChildren}
          </>
        )}
      </div>
      <div>
        {imagePath && title && <h2 className="title-sm">{title}</h2>}
        {content &&
          content.map((content, i) => {
            const { text, title } = content;
            return (
              <React.Fragment key={i}>
                {title && <h3>{title}</h3>}
                {text && <p>{text}</p>}
              </React.Fragment>
            );
          })}
        {children}
        {src && label && (
          <Button
            {...buttonProps}
            to={src}
            reversed={styleType === 'reversed'}
            gutter={{ top: '1.5rem' }}>
            {label}
          </Button>
        )}
      </div>
    </StyledContentBlock>
  );
};

ContentBlock.propTypes = {
  buttonProps: PropTypes.object,
  children: PropTypes.any,
  content: PropTypes.array,
  gutter: PropTypes.object,
  imagePath: PropTypes.string,
  maxWidth: PropTypes.string,
  reverse: PropTypes.bool,
  styleType: PropTypes.oneOf(['default', 'card', 'reversed']),
  title: PropTypes.string,
  titleChildren: PropTypes.any,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
