import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getFilteredArray } from '../../utils/getFilteredArray';

// Components
import { Card, CardGroup } from '..';

// Data
import serviceList from '../../data/service-list';

export const ServiceFeed = ({
  columnCount,
  data = serviceList,
  exclude,
  feedCount,
  headerProps,
  scrollOnMobile,
  showCta,
  ...props
}) => {
  const filteredData = getFilteredArray(data, exclude);
  return (
    <>
      <CardGroup
        columnCount={columnCount}
        scrollOnMobile={scrollOnMobile}
        headerProps={{
          title: 'Our Other Services',
          text: 'If you don’t see what you are looking for here, please contact us and we can provide you with more options.',
          ...headerProps,
        }}
        {...props}>
        {filteredData.map((data, i) => {
          const { url, title, description, imagePath } = data;
          if (feedCount ? i < feedCount : i < filteredData.length)
          return (
            <Card
              key={url}
              title={title}
              text={description}
              imagePath={imagePath}
              cta={{ src: url, label: showCta ? 'Learn More' : null }}
            />
          );
        })}
      </CardGroup>
    </>
  );
};

ServiceFeed.propTypes = {
  columnCount: PropTypes.number,
  data: PropTypes.array,
  feedCount: PropTypes.number,
  filter: PropTypes.array,
  headerProps: PropTypes.object,
  scrollOnMobile: PropTypes.bool,
  showCta: PropTypes.bool,
};
