import styled from 'styled-components';

import { font, media } from '../../constants/theme';

export const StyledHeaderBlock = styled.header`
  max-width: 45rem;

  @media ${media.notMobile} {
    margin-bottom: 4rem;
    h2 {
      font-size: ${font.size.heading.default};
    }
    p {
      font-size: ${font.size.base.default};
      margin-top: 1.5rem;
    }
  }
  @media ${media.mobile} {
    margin-bottom: 2rem;
    
    h2 {
      font-size: ${font.size.heading.mobile};
    }
    p {
      font-size: ${font.size.base.mobile};
      margin-top: 1rem;
    }
  }
`;
