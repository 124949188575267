import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Container } from '../../components';

import { StyledEndHero } from './styles';
import { ContentBlock } from '../contentBlock';

export const EndHero = ({
  title,
  text,
  cta,
  ...props
}) => {
  return (
    <StyledEndHero {...props}>
      <Container>
        <ContentBlock
          title={title}
          content={[{text}]}
          cta={cta}
          buttonProps={{styleType: 'brand'}}
        />
      </Container>
    </StyledEndHero>
  );
};

EndHero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
