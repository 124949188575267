import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils
import { fetch, getPostsUrl, createSlug, API_PATH } from '../../utils/dao';
import { getFilteredArray } from '../../utils/getFilteredArray';

// Components
import { Card, CardGroup } from '../../components';

export const NewsFeed = ({ exclude, ...props }) => {
  const params = {
    _limit: 5,
    _start: 0,
    _sort: 'published_at:desc',
  };

  const [posts, setPosts] = useState([]);

  const getPosts = (next) => {
    fetch(getPostsUrl(params, next), null).then((data) => {
      setPosts(data);
    });
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <CardGroup
      headerProps={{
        title: 'Latest News & Insights',
        text:
          'Keep up to date with the latest news, updates and promotions from West Coast Bullion & Currency Exchange, as well as market trends and insights to look out for.',
      }}
      {...props}>
      {posts && posts.length > 0 && (
        <>
          {posts.map(({ id, title, published_at: date, image }, i) => {
            if (i < 2)
              return (
                <Card
                  key={id}
                  {...{
                    title,
                    date,
                    imagePath: `${API_PATH}${image.formats.small.url}`,
                    cta: { src: `/post/${id}/${createSlug(title)}` },
                  }}
                  styleType="news"
                />
              );
          })}
          <div>
            {posts
              .slice(2)
              .map(({ id, title, published_at: date, image }, i) => {
                return (
                  <Card
                    key={id}
                    {...{
                      title,
                      date,
                      imagePath: `${API_PATH}${image.formats.small.url}`,
                      cta: { src: `/post/${id}/${createSlug(title)}` },
                    }}
                    styleType="news"
                    condensed
                    gutter={{ bottom: '1.5rem' }}
                  />
                );
              })}
          </div>
        </>
      )}
    </CardGroup>
  );
};

NewsFeed.propTypes = {
  posts: PropTypes.array,
};
