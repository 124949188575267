import styled from 'styled-components';
import { colors, media, font } from '../../constants/theme';

export const StyledFooter = styled.footer`
  background-color: ${colors.black};
  padding: 6rem 0;

  &,
  a:not(:hover) {
    color: #fff;
  }

  a {
    font-weight: ${font.weight.bold};
    text-decoration: none;

    &:hover {
      color: ${colors.brand};
    }
  }
`;

export const StyledFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1.4fr auto auto;
  gap: 3rem 4rem;
  grid-template-areas:
    'logo email phone'
    'address links-1 links-2'
    'copyright copyright copyright';

  @media ${media.tablet} {
    grid-template-columns: auto;
    gap: 3rem;
    grid-template-areas:
      'logo logo'
      'address address'
      'email phone'
      'links-1 links-2'
      'copyright copyright';
  }

  @media ${media.mobile} {
    grid-template-columns: auto;
    gap: 1.5rem;
    grid-template-areas:
      'logo'
      'email'
      'phone'
      'address'
      'links-1'
      'links-2'
      'copyright';
  }

  .logo {
    grid-area: logo;

    svg {
      width: 280px;
      max-width: 100%;

      @media ${media.mobile} {
        width: 250px;
        margin-bottom: 1rem;
      }
    }
  }

  .email,
  .phone,
  .address {
    h2,
    address {
      display: flex;
      align-items: center;
    }

    h2 {
      font-size: ${font.size.cardHeading.default};
    }

    svg {
      width: 24px;
      margin-right: 1rem;
    }
  }

  .email {
    grid-area: email;

    a {
      text-transform: lowercase;
    }
  }

  .phone {
    grid-area: phone;
  }

  .address {
    grid-area: address;

    address {
      margin: 1rem 0 2rem;
    }

    p {
      font-size: ${font.size.info.default};
      max-width: 600px;
    }
  }

  .links-1 {
    grid-area: links-1;
  }

  .links-2 {
    grid-area: links-2;
  }

  .links-1,
  .links-2 {
    line-height: 2.5;
  }

  .copyright {
    grid-area: copyright;
    line-height: 2;
    font-size: ${font.size.info.default};

    @media ${media.mobile} {
      font-size: ${font.size.fine.default};
    }
  }
`;
