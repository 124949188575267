import styled from 'styled-components';
import { media, header, colors, font, shadow } from '../../constants/theme';

export const StyledHero = styled.header`
  position: relative;
  color: #fff;

  @media ${media.fullNav} {
    padding-top: ${header.fullHeight};
  }
  @media ${media.condensedNav} {
    padding-top: ${header.condensedHeight};
  }
`;

export const StyledHeroContent = styled.div`
  @media ${media.notMobile} {
    padding: 4rem 0 16rem;
    max-width: 45rem;
    h1 {
      font-size: ${font.size.hero.default};
    }
    p {
      display:none;
    }
  }

  @media ${media.tablet} {
    h1 {
      font-size: ${font.size.hero.mobile};
    }
    p {
      display:none;
    }
  }

  @media ${media.mobile} {
    padding: 4rem 0 16rem;
    h1 {
      font-size: ${font.size.heading.mobile};
    }
    p {
      line-height: 1.6;
      margin-top: 1rem;
      font-size: ${font.size.info.mobile};
    }
  }
`;

export const StyledBackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  > div {
    height: 100%;
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.heroTint};
  }
`;

