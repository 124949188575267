import styled from 'styled-components';

import { media, colors, font } from '../../constants/theme';

export const StyledNewsPost = styled.article`
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
`;

export const StyledArticleWrapper = styled.div`
  .article-date {
    font-weight: ${font.weight.bold};
  }
  .article-share {
    margin: 2rem 0;
    h3 {
      font-family: ${font.family.default};
      color: ${colors.mute};
      font-size: ${font.size.info.default};
      font-weight: ${font.weight.bold};
      text-transform: uppercase;
    }
  }
`;

export const StyledArticleBody = styled.div`
  font-size: 18px;

  @media ${media.notDesktop} {
    font-size: ${font.size.body.mobile};
  }

  p {
    margin-bottom: 2rem;
  }

  ul {
    list-style: disc;
    margin: 1.25rem;
    line-height: 1.5;
  }

  figure {
    margin: 2rem 0;
  }

  iframe{
    max-width: 100% !important;
    margin-bottom: 2rem;
  }
`;

export const StyledFeaturedImage = styled.div`
  margin-bottom: 2rem;
`;
