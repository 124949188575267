import styled from 'styled-components';

import { media, colors, shadow, font } from '../../constants/theme';

export const StyledHomeContent = styled.div`
  margin-top: -11rem;
`;

export const StyledHeroBoxes = styled.div`
  display: flex;

  @media ${media.desktop} {
    > div {
      box-shadow: ${shadow.card};
    }
  }

  @media ${media.notDesktop} {
    flex-direction: column;
  }

  .gold-widget {
    background-color: #fff;

    @media ${media.desktop} {
      flex-grow: 1;
      padding: 3rem;
    }

    @media ${media.notDesktop} {
      box-shadow: ${shadow.card};
      order: 1;
      width: 100%;
      padding: 2rem 2.5rem;
    }
  }
`;

export const StyledHeroTagline = styled.div`
  flex-grow: 0;
  color: #fff;
  background-color: ${colors.brandDark};
  padding: 3rem 3.75rem;

  p {
    margin: 1.5rem 0;
  }

  @media ${media.desktop} {
    width: 42rem;
    max-width: 100%;

    h2 {
      font-size: ${font.size.subheading.default};
    }
  }

  @media ${media.notDesktop} {
    order: 2;
    margin-top: 2rem;

    h2 {
      font-size: ${font.size.heading.mobile};
    }
  }

  @media ${media.mobile} {
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 4.5rem 2rem;
  }
`;
