import styled, { css } from 'styled-components';
import { colors, media, font } from '../../constants/theme';

export const StyledOrderProcess = styled.div`
  h2 + svg {
    margin-top: 1rem;
    width: 120px;

    @media ${media.notDesktop} {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  h3.content-block-heading {
    font-size: ${font.size.body.default};
    font-family: ${font.family.default};
    margin: 0 0 1.5rem;
  }

  ul {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 1.25rem;
    row-gap: 0.5rem;

    @media ${media.tablet} {
      grid-template-columns: auto auto;
    }
    @media ${media.mobile} {
      grid-template-columns: auto;
    }

    li {
      display: flex;
      align-items: center;
    }

    svg {
      width: 16px;
      margin-right: 8px;
      color: green;
    }
  }

  > div > div > div:last-child {
      margin-top: 1.75rem;
  }
`;
