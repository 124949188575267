import React from 'react';
import PropTypes from 'prop-types';

// Components
// Components
import { SocialShare, DateTime } from '../../components';
import {
  StyledNewsPost,
  StyledFeaturedImage,
  StyledArticleWrapper,
  StyledArticleBody,
} from './styles';

export const NewsPost = ({
  imagePath,
  title,
  children,
  dateTimeProps,
  shareProps,
  ...props
}) => {
  const ShareThisArticle = (
    <div className="article-share">
      <h3>Share this article</h3>
      <SocialShare {...shareProps} />
    </div>
  );
  return (
    <StyledNewsPost {...props}>
      <StyledFeaturedImage>
        <img src={imagePath} alt={title} />
      </StyledFeaturedImage>
      <StyledArticleWrapper>
        <DateTime
          className="article-date"
          prefix="Posted on "
          {...dateTimeProps}
        />
        {ShareThisArticle}
        <StyledArticleBody>{children}</StyledArticleBody>
        {ShareThisArticle}
      </StyledArticleWrapper>
    </StyledNewsPost>
  );
};

NewsPost.propTypes = {
  children: PropTypes.any,
  imagePath: PropTypes.string,
  title: PropTypes.string,
  dateTimeProps: PropTypes.object,
  shareProps: PropTypes.object,
};
